import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from "react-router-dom";

import { RiArrowGoBackLine, RiCheckLine } from 'react-icons/ri';

import { getMissionFromNamespace } from '../jsonData/DataWrangler';
import BackgroundDecorations from './BackgroundDecorations';

const Mission = () => {

    let history = useHistory();

    let { namespace } = useParams();

    const [mission, setMission] = useState(getMissionFromNamespace(namespace));
    const [filteredMission, setFilteredMission] = useState([]);

    useEffect(() => {

        let filtered = [];

        for (let i = 0; i < mission.Variables.length; i++) {

            if (process.env.REACT_APP_DEBUG_MODE === 'false') {
                if (mission.Variables[i].Value.toLowerCase() === 'false') {
                    filtered.push(mission.Variables[i]);
                    break;
                }
            }

            filtered.push(mission.Variables[i]);
        }
        setFilteredMission(filtered);

    }, [mission])

    return (
        <div className="h-screen flex flex-col relative">

            <div className="flex items-center w-full h-24 text-xl relative">
                <p className="w-full text-2xl text-center text-persona-white font-header tracking-wide">
                    {mission.Description}
                </p>
            </div>

            <div className="relative flex-row bg-persona-white rounded-3xl mx-5 px-5 h-full mb-5 overflow-y-auto no-scrollbar shadow-lg">
                <div className="space-y-3 py-5">
                    {
                        filteredMission.map((step, key) => {
                            return (
                                <MissionStep key={key} step={step} index={key} />
                            )
                        })
                    }
                </div>
            </div>

            <div
                className="bg-persona-greenLight my-5 rounded-full mx-auto w-20 h-20 flex items-center shadow-lg cursor-pointer"
                onClick={() => history.goBack()}>
                <RiArrowGoBackLine className="mx-auto h-12 w-12 m-8 text-persona-white" />
            </div>
        </div>
    )
}

const MissionStep = ({ step, index }) => {

    const completed = step.Value.toLowerCase() === 'true';

    const styling = () => {
        if (completed)
            return 'bg-gradient-to-r from-persona-greenDark to-persona-greenLight text-persona-greenLight';
        else
            return 'bg-persona-incompleteMission text-black text-opacity-22';
    }

    return (
        <div className={`flex flex-row items-center rounded-lg shadow-md py-2 relative ${styling()}`}>
            <p className="text-3xl pl-3 pr-3 font-bold">{index + 1}</p>
            <div className="bg-black opacity-22 m-auto h-8 w-0.5"></div>
            <p className={`w-full text-base mr-12 ml-3 ${completed ? 'text-persona-white text-opacity-80' : 'text-black text-opacity-50'}`}>
                {step.Description}
            </p>
            <div className="absolute right-3">
                {
                    completed ?
                        // completed
                        <div className="bg-persona-greenMiddle border-2 border-persona-white rounded-full h-8 w-8 flex items-center">
                            <RiCheckLine className="mx-auto h-5 w-5 text-persona-white " />
                        </div> :
                        // not completed
                        <div className="border-2 border-persona rounded-full h-8 w-8">
                        </div>
                }
            </div>
        </div>
    )
}

export default Mission
