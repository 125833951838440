import create from 'zustand'
import {getVariableWithNamespace, updateGlobalVar, updateGlobalVarByNamespace} from "../jsonData/DataWrangler";
import produce from "immer";

const getLocalStorage = (key) => JSON.parse(window.localStorage.getItem(key));
const setLocalStorage = (key, value) => window.localStorage.setItem(key, JSON.stringify(value));

export const useGeneralStore = create((set, get) => ({
    
    // popups
    shownPopup: null,
    collectedPopups: [],
    addPopup: (popupDetails) => set(state => {

        if (state.collectedPopups.some(e => e.id === popupDetails.id) === false){
            state.collectedPopups.push(popupDetails);
            state.shownPopup = popupDetails;
        }
    }),
    removePopup: () => set(state => {
        state.shownPopup = null;
    }),
    
    // inventory
    inventoryShown: false,
    inventoryItems: [],
    setInventoryItems: (items, cb) => set(state => {
        state.inventoryItems = items;
        state.inventoryItems.callbackFunction = cb;
    }),
    clearInventory: () => set(state => {
        state.inventoryItems = [];
        
        // reset all items back to ""
        let cleanList = getVariableWithNamespace('PickedInventoryItems')
        const updatedPickedItemList = produce(cleanList, draft => {
            for (let i = 0; i < cleanList.Variables.length; i++) {
                draft.Variables[i].Value = "";
            }
        })
        updateGlobalVar("InventoryItemsSelected", "0", ["GameSettings"]);
        updateGlobalVarByNamespace(cleanList.Namespace, updatedPickedItemList);
    }),
    setInventoryState: (newState => set(state => {
        state.inventoryShown = newState;
    })),
    
    headerImagePath: null,
    headerText: "",
    
    // errors
    shownError: null,
    showError: (str) => set(state => {
        state.shownError = str;
    }),
    removeError: () => set({ shownError: null }),
    
    // rnd numbers
    sceneRandom: 0.5,
    rerollSceneRandom: () => set(state => {
        const rnd = Math.random();
        state.sceneRandom = rnd;
    })
}))

export default useGeneralStore;