import React, { useState, useEffect }  from 'react'
import useStore from "../Stores/userStore";
import { useHistory } from "react-router-dom";
import { useAuth } from "../Auth/auth";
import {RiArrowGoBackLine} from "react-icons/ri";

const Account = () => {
    
    const history = useHistory();
    const [isTokenValid, error, setError, clearError] = useStore(state => [state.isTokenValid, state.error, state.setError, state.clearError]);

    const [inputEmail, setInputEmail] = useState("tester@personunknown.com");
    const [inputPassword, setInputPassword] = useState("persona");
    
    const auth = useAuth();
    const [loading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);
    
    useEffect(async () => {
        if (process.env.REACT_APP_FEEDBACK_VERSION === 'false')
            return;
        
        setLoggedIn(false);
        setLoading(true);
        
        const signedIn = await auth.isSignedIn();
        
        setLoggedIn(signedIn);
        setLoading(false);
    }, [])

    function tryLogin(e) {
        e.preventDefault();
        clearError();

        auth.signIn(inputEmail, inputPassword)
            .then((response) => {

                if (response.status !== 200 || response.error) {
                    setError(response.error.toString());
                    return;
                }

                if (isTokenValid())
                    history.push("/");
                else
                    setError(response)
            })
            .catch(err => {

            })
            .finally(() => {
                //setInputEmail("");
                //setInputPassword("");
            })
    }
    
    async function tryLogout(e) {
        e.preventDefault();
        clearError();

        setLoggedIn(false);
        const loggedOutResult = await auth.signOut();
        
        if (loggedOutResult.error) {
            setError(loggedOutResult.error)
        }
    }
    
    if (loading === true) {
        return (
            <div className="pt-4 flex flex-col h-screen relative">
                <div className="text-white text-3xl text-center my-10">
                    Loading
                </div>
            </div>
        )
    }
    
    // shown when signed in
    if (loggedIn) {
        return (
            <div className="pt-4 flex flex-col h-screen relative mx-5">
                <div className="text-white text-3xl text-center my-10">Account page</div>
                <div className="flex flex-col space-y-5 text-white h-full">
                    <div className="flex flex-row items-center">
                        <label className='w-1/3 font-semibold'>Email address</label>
                        <p>{auth.tokenData.email}</p>
                    </div>
                    <div className="flex flex-row items-center">
                        <label className='w-1/3 font-semibold'>Unique user ID</label>
                        <p>{auth.user.uuid}</p>
                    </div>
                    <div className="flex flex-row items-center">
                        <label className='w-1/3 font-semibold'>Permission level</label>
                        <p>{auth.tokenData.permission_level}</p>
                    </div>
                    <button type="submit" className="flex-grow-0 w-full bg-persona-deny font-semibold cursor-pointer bg-opacity-80 hover:bg-opacity-100 hover:shadow-lg text-lg rounded-lg text-white flex-1 text-center py-5"
                            onClick={ tryLogout }>
                        SignOut
                    </button>
                </div>
                <div
                    className="bg-persona-greenLight my-5 rounded-full mx-auto w-20 h-20 flex items-center shadow-lg cursor-pointer"
                    onClick={() => history.goBack()}>
                    <RiArrowGoBackLine className="mx-auto h-12 w-12 m-8 text-persona-white" />
                </div>
            </div>
        )
    }
    
    // shown when signed out
    return (
        <div className="pt-4 flex flex-col h-screen relative mx-5">
            <div className="text-white text-3xl text-center my-10">Account page</div>
            <form className="flex flex-col space-y-5 h-full">

                <div className="flex flex-row items-center">
                    <label className='w-1/3 text-white font-semibold'>Email address*</label>
                    <input className='w-2/3 rounded-lg'
                           key="input_email"
                           type="email"
                           placeholder="email@example.com"
                           value={inputEmail}
                           required
                           onChange={(e) => setInputEmail(e.target.value)}/>
                </div>

                <div className="flex flex-row items-center">
                    <label className='w-1/3 text-white font-semibold'>Password*</label>
                    <input className='w-2/3 rounded-lg'
                           key="input_pw"
                           type="password"
                           value={inputPassword}
                           required
                           onChange={(e) => setInputPassword(e.target.value)}/>
                </div>

                <button type="submit" className="flex-grow-0 w-full bg-persona-accept font-semibold cursor-pointer bg-opacity-80 hover:bg-opacity-100 hover:shadow-lg text-lg rounded-lg text-white flex-1 text-center py-5"
                        onClick={ tryLogin }>
                    Signin
                </button>

                {(Object.keys(error).length !== 0 && error.constructor === Object) &&
                    <div className="bg-red-800 text-white text-center py-1 rounded-full"
                         onClick={() => clearError()}>
                        {error.text}
                    </div>
                }
            </form>
            <div
                className="bg-persona-greenLight my-5 rounded-full mx-auto w-20 h-20 flex items-center shadow-lg cursor-pointer"
                onClick={() => history.goBack()}>
                <RiArrowGoBackLine className="mx-auto h-12 w-12 m-8 text-persona-white" />
            </div>
        </div>
    )
}

export default Account