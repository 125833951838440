var path = require('path');

const metadata = require('../metadata.json');

const dirName = path.resolve("./");

const jsonData = require(dirName + metadata.cleanedJson);

var fullPath = path.join(__dirname, '../metadata.json');

var fs = require('fs');
fs.readFile(fullPath, function (err, content) {
    if (err) throw err;
    var metadata = JSON.parse(content);

    const entryID = metadata.entryID;

    ExtractQrCodes(entryID);
})

function ExtractQrCodes(entryID) {

    const scanKnopNode = jsonData.Packages[0].Models.find(node => node.Properties.Id === entryID);
    const allFlowFragments = jsonData.Packages[0].Models.filter((list) => list.Type === 'FlowFragment');

    if (scanKnopNode.Properties === undefined)
        console.log("ERROR: " + entryID + " has no Properties");

    let outputTargets = scanKnopNode.Properties.OutputPins[0].Connections;

    let targetIdArray = Array.from(outputTargets, x => x.Target);

    let qrIdWithNameArray = [];

    console.log("Extracted QR codes:")
    for (const targetId in targetIdArray) {
        // console.log("Finding: ", targetIdArray[targetId]);
        const label = allFlowFragments.find(ff => ff.Properties.Id === targetIdArray[targetId])?.Properties.DisplayName;

        if (label != null) {
            qrIdWithNameArray.push({
                value: targetIdArray[targetId],
                label: label
            });
            console.log((parseInt(targetId) + 1), "\t", targetIdArray[targetId], label)
        }
    }

    var fs = require('fs');

    fs.readFile(fullPath, function (err, content) {
        if (err) throw err;
        var metadata = JSON.parse(content);
        metadata.startQRCodes = qrIdWithNameArray;

        fs.writeFile(fullPath, JSON.stringify(metadata, null, 4), function (err) {
            if (err) throw err;
        })
    });
}