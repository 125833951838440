import React, { useState, useEffect } from 'react';
import { Switch, Route, useLocation, useHistory, Navigate, Redirect } from 'react-router-dom';
import { legacyQRDictionary } from './legacy/LegacyHandler';

import HomeScreen from './HomeScreen';
import Mission from './Mission';
import Guide from './Guide';
import Scene from './Scene';
import ScanScreen from './ScanScreen';
import {
    resetDialogHistory,
    determineStartingPoint,
    executeNPCAction,
    getSelectedSceneFromId,
    isPlayerAction,
    myConsoleLog
} from '../jsonData/DataWrangler';
import ProfileScreen from './ProfileScreen';
import BackgroundDecorations from './BackgroundDecorations';

import { useTransition, animated } from 'react-spring';

import useTimeout from '../customHooks/useTimeout';
import LiveJsonUpdate from './debug/LiveJsonUpdate';
import Collectables from './Collectables';
import metadata from '../metadata.json';
import QrRedirector from "./QrRedirector";
import ArticyVariablesDisplay from "./debug/ArticyVariablesDisplay";
import Account from "./Account";
import useUserStore from "../Stores/userStore";

const Main = ({ onChangeScene, addMissionUpdate, resetCurrentScene }) => {

    let history = useHistory();
    
    const [firstTime, setFirstTime] = useState(true);
    const [introConversationBtnVisible, setBtnVisible] = useState(false);

    const { clear, reset } = useTimeout(() => {
        if (firstTime === true) {
            setFirstTime(false);
            setBtnVisible(true);
        }
    }, 2000);


    const [id, setId] = useState(null);

    function handleScanResultCallback(scanResult) {

        console.log('scanresult = ', scanResult);

        const sanitized = sanitizeScannedResult(scanResult);
        if (sanitized === undefined) {
            history.replace('/');
            return;
        }

        let startingPoint = determineStartingPoint(sanitized);
        let newScene = {};

        if ((startingPoint === null) || (startingPoint.length < 1)) {
            // invalid QR code,
            newScene = { sceneType: '/scan', sceneId: 0 };
        }
        else {

            // valid QR code
            // @@@ If more than 1 starting point, we need the Persona Selector screen
            if (startingPoint.length > 1) {
                myConsoleLog("Meer dan 1 starting point! Length= " + startingPoint.length);
            }
            // if we change the scene, reset the dialog history
            resetDialogHistory();
            let startingId = startingPoint[0];
            newScene = { sceneType: 'scene', sceneId: startingId };
            if (!isPlayerAction(getSelectedSceneFromId(startingId))) {
                newScene = executeNPCAction(getSelectedSceneFromId(startingId));  // a.o. to fill the dialogHistory to the right point
                if (newScene === null) {
                    myConsoleLog("executeNPCAction returns null!");
                    newScene = { sceneType: 'scene', sceneId: startingId };
                }
            }
            //console.log("Startingpoint is now " + newScene.sceneId);
            setId(newScene.sceneId);
        }
        onChangeScene(newScene); // call the parent
    };

    // maybe add more checks later
    function sanitizeScannedResult(checkVal) {
        
        let origin = null;
        try {
            if (checkVal.includes('.com') || checkVal.includes('.nl')) {

                if (!checkVal.startsWith('http')) {
                    checkVal = 'https://' + checkVal;
                }

                const url = new URL(checkVal)
                origin = url.origin + `/qr/`;
            }
        } catch (_) {}

        if (origin != null && checkVal.startsWith(origin)) {

            const lowercaseKey = checkVal.substring(origin.length).toLowerCase();
            
            const startId = metadata.startQRCodes.filter(e => e.identifier.toLowerCase().replace(/\s/g,'') === lowercaseKey);

            if (startId != null)
                return startId[0].value;

            return null;
        }
        else {
            const startId = metadata.startQRCodes.filter(e => e.identifier.toLowerCase().replace(/\s/g,'') === checkVal.toLowerCase())[0]?.value;

            if (startId != null)
                return startId;

            const dictEntry = legacyQRDictionary.find(entry => entry.originalValue === checkVal);
            if (dictEntry !== undefined) {
                console.log(`Legacy value of ${dictEntry.originalValue} found, converting it to ${dictEntry.convertedValue}`);
                return dictEntry.convertedValue;
            }

            return checkVal;
        }
    }

    function doChangeScene(newScene) {

        //let newUrl = `/scene/${newScene.sceneId}`;
        // @@@ Misschien moet hier determineStartingPoint ook in? Voor als er Persona Selector nodig is.

        setId(newScene.sceneId);
        //this.setState({ id: newScene.sceneId });        // This is the level where we change the state!
        onChangeScene(newScene);    // call the parent
    };


    const initial = {
        opacity: 1,
    };
    const from = {
        opacity: 0,
    };
    const leave = {
        opacity: 0,
        display: 'none',
    };
    const enter = {
        opacity: 1,
    };

    const location = useLocation();
    const transition = useTransition(location, {
        initial,
        from,
        enter,
        leave,
        config: { duration: 300 },
    })

    return (
        <div className="max-w-screen-sm m-auto">

            <BackgroundDecorations />

            {transition((values, item, key) => (
                <animated.div key={key} style={values}>
                    <Switch location={item}>
                        <Route path='/scan' exact render={() => <ScanScreen handleScan={handleScanResultCallback} />} />
                        <Route path='/scene' render={() => <Scene selectedId={id} onChangeScene={doChangeScene} addMissionUpdate={addMissionUpdate} />} />

                        <Route path='/qr/:key' render={() => <QrRedirector handleScanResult={handleScanResultCallback} />} />

                        <Route path="/mission/:namespace" render={() => <Mission />} />
                        <Route path="/profile" render={() => <ProfileScreen />} />

                        <Route path='/collectables' render={() => <Collectables />} />
                        <Route path='/uitleg' render={() => <Guide />} />

                        {
                            process.env.REACT_APP_DEBUG_MODE === 'true' &&
                            <ProtectedRoute exact path="/update" component={LiveJsonUpdate} />
                        }
                        {
                            process.env.REACT_APP_DEBUG_MODE === 'true' &&
                            <ProtectedRoute exact path="/vars" component={ArticyVariablesDisplay} />
                        }
                        {
                            process.env.REACT_APP_FEEDBACK_VERSION === 'true' &&
                            <Route path='/account' render={() => <Account />} />
                        }
                               
                        <Route path='/' render={() => <HomeScreen resetCurrentScene={resetCurrentScene} onChangeScene={onChangeScene} introConvoBtnVisible={introConversationBtnVisible} handleScanShortcut={handleScanResultCallback} />} />
                    </Switch>
                </animated.div>
            ))}
        </div>
    );
}

function ProtectedRoute({ component: Component, ...restOfProps }) {

    const isTokenValid = useUserStore(state => state.isTokenValid);
    
    return (
        <Route
            {...restOfProps}
            render={(props) =>
                isTokenValid() ? <Component {...props} /> : <Redirect to="/" />
            }
        />
    );
}

export default Main;