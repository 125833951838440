import React, { useState } from 'react';
import emailjs, { init } from 'emailjs-com';
import { getNodeWithExternalId } from "../jsonData/DataWrangler";

const SendProfileEmail = ({ profileData }) => {

    init(process.env.REACT_APP_EMAIL_USER_ID);

    const emailHeaderText = getNodeWithExternalId('emailHeaderName')?.Properties.Text || 'Profiel versturen?';

    const [sendingStatus, setSendingStatus] = useState('null');

    let emailParams = {
        to_email: '',
    };

    const [toEmail, setToEmail] = useState('');
    const [lastSentDate, setLastSentDate] = useState(null);
    const allowSendingInterval = 5 * 60;

    const sendEmail = (e) => {
        e.preventDefault();

        setSendingStatus('null');

        if (process.env.REACT_APP_CAN_SEND_EMAIL === 'false') {
            setSendingStatus("Het is in deze demo modus helaas niet mogelijk om je profiel te versturen.\r\n" +
                "Je kunt wel een screenshot maken van je profiel en deze delen.")
            return;
        }

        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(toEmail) === false) {
            console.log("Invalid email!")
            return;
        }

        let timeBetweenSent = 6 * 60;

        // only account for interval when there has been at least an email sent already
        if (lastSentDate !== null) {
            timeBetweenSent = (new Date() - lastSentDate.getTime()) / 1000;
        }

        // if interval time has not passed
        if (timeBetweenSent < allowSendingInterval) {

            const min = Math.floor((allowSendingInterval - timeBetweenSent) / 60);
            const sec = Math.round((allowSendingInterval - timeBetweenSent) - min * 60);

            setSendingStatus(`Probeer het over ${min}:${sec.toString().padStart(2, "0")} nogmaals`);
            alert(`Je hebt jezelf je profiel al gestuurd.\n\nOver ${min}:${sec.toString().padStart(2, "0")} kun je het nogmaals versturen.`);
            return;
        }

        setEmailParams();

        setSendingStatus("E-mail wordt verstuurd...");

        emailjs.send(process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_ID, emailParams, process.env.REACT_APP_EMAIL_USER_ID)
            .then(function (response) {
                console.log('SUCCESS!', response.status, response.text);
                setLastSentDate(new Date());
                setSendingStatus('E-mail succesvol verstuurd!');

            }, function (error) {
                console.log('FAILED...', error);
                setSendingStatus('E-mail niet verstuurd: ' + error.status);
            })
    }

    const setEmailParams = () => { emailParams.to_email = toEmail; }

    const emailInputChange = (input) => { setToEmail(input.target.value); }

    return (
        <div className="w-full px-2 py-2 mb-2 bg-persona-greenLight rounded-lg shadow-lg" >
            <p className="text-center text-lg mb-1 text-persona-white font-header">{emailHeaderText}</p>
            <form onSubmit={sendEmail} className="w-full flex flex-row mt-auto rounded-lg">
                <input className="flex-1 w-0 px-3 text-black text-opacity-70 py-2 rounded-l-lg text-md" required type="email" name="to_email" onChange={emailInputChange} placeholder="e-mailadres.." />
                <input className="flex-0 pl-4 pr-4 rounded-r-lg bg-persona text-persona-white tracking-wide" type="submit" value="Send" />
            </form>
            {
                sendingStatus === 'null' ?
                    null :
                    <div className="text-center mt-2 text-persona-white whitespace-pre-wrap">{sendingStatus}</div>
            }
        </div>
    );
}

export default SendProfileEmail
