const fs = require('fs');
var path = require('path');

const metadata = require('../metadata.json');
const dirName = path.resolve("./");

// change path to original file!
const jsonData = require(dirName + metadata.rawJson);

// change save path!
const saveToPath = dirName + metadata.cleanedJson;

// minify = false, means human readable, set this to true to minify the file for deployment
const minify = false;

// specify found keys to remove
const keysToRemove = [
    "Color",
    "Size",
    "ZIndex",
    "Position",
    "StageDirections",
    "ShortId",
    "TechnicalName",
    "Label",
    "ObjectDefinitions",
    "Hierarchy",
    "IsDefaultPackage",
    "Vertices",
    "Transform",
    "ShapeType",
    "OutlineColor",
    "OriginalSource",
    "ScriptMethods",
    "ViewBox"
];

function writeToFile(readable) {

    const startLineCount = JSON.stringify(jsonData, null, 4).split(/\r\n|\r|\n/).length;
    console.log(`Original file has ${startLineCount} lines`);
    let endLineCount = startLineCount;

    try {
        let data = null;
        if (!minify)
            data = JSON.stringify(cleanJsonData(jsonData), null, 4);
        else
            data = JSON.stringify(cleanJsonData(jsonData));

        fs.writeFileSync(saveToPath, data);

        endLineCount = data.split(/\r\n|\r|\n/).length;

        //console.log(`New file has ${endLineCount} lines [ -${((1.0 - (endLineCount / startLineCount)) * 100.0).toFixed(1)}% OR -${(startLineCount - endLineCount)} lines ]`);

        console.log(`Reduced json file: ${endLineCount} lines [ -${((1.0 - (endLineCount / startLineCount)) * 100.0).toFixed(1)}% OR -${(startLineCount - endLineCount)} lines ]`)
        console.log(`Cleaned file saved at ${saveToPath}`);

    } catch (error) {
        console.error(error);
    }
}

function cleanJsonData(jsonData) {
    return JSON.parse(JSON.stringify(jsonData, replacer));
}

function replacer(key, value) {
    if (keysToRemove.includes(key)) {
        return undefined;
    }
    return value;
}

writeToFile(minify);