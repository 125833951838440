import React, {useEffect} from 'react'
import {Redirect, useHistory, useParams} from "react-router-dom";
import metadata from '../metadata.json';

const QrRedirector = ({handleScanResult}) => {

    const { key } = useParams();
    
    console.log('key', key);
    
    let history = useHistory();
    
    useEffect(() => {
        
        handleScanResult(key);
    }, [])
    
    return null;
}

export default QrRedirector