import React, { useState, useEffect } from 'react'
import { getJsonData, loadDataFromJson } from '../../jsonData/DataWrangler';
import { BsCloudUpload } from 'react-icons/bs';
import { MdOutlineDelete } from 'react-icons/md';
import { useHistory } from "react-router-dom";
import useLocalStorage from '../../customHooks/useLocalStorage';

const LiveJsonUpdate = () => {

    const history = useHistory();

    const [loadedData, setLoadedData] = useLocalStorage('data', null);

    const [selectedFile, setSelectedFile] = useState();
    const [editableJsonData, setEditableJsonData] = useState();

    useEffect(() => {
        console.log("Changed data");
        setEditableJsonData(loadedData);
    }, [])

    const [statusText, setStatusText] = useState('');

    const handleFileUpload = (event) => {

        if (!event.target.files[0]) return;

        setSelectedFile(event.target.files[0]);

        let reader = new FileReader();
        reader.onload = setContent;

        reader.readAsText(event.target.files[0]);
    }

    const handleInputFieldChange = (event) => {

        setEditableJsonData(JSON.parse(event.target.value));
    }

    const setContent = (event) => {
        let jsonContent;

        try {
            jsonContent = JSON.parse(event.target.result);

            setEditableJsonData(jsonContent);
            setStatusText('upload of JSON file success!');
        }
        catch (err) {
            setStatusText('Error: ' + err);
            throw err;
        }
    }

    const reloadAppWithData = () => {
        if (editableJsonData === undefined) {
            console.log("No data to use");
            setStatusText('No data to use...');
        }

        setLoadedData(editableJsonData);

        history.push('/');
        window.location.reload();

        console.log("Loading app with updated data!");
    }

    const clearLocalData = () => {
        localStorage.removeItem('data');

        loadDataFromJson(null, (data) => {

            setLoadedData(data);
            setEditableJsonData(data);
        });
    }

    return (
        <div className="pt-4 flex flex-col h-screen relative bg-persona-white bg-opacity-20">
            <p className="w-full font-header text-center text-xl px-8 text-persona-white pt-5">Update your JSON here</p>
            <p className="w-full font-header text-center text-xl px-8 text-persona-white">ONLY FOR DEVELOPMENT PURPOSES</p>

            <div className="flex mt-6 flex-row px-5 space-x-2">
                <label className="flex flex-grow cursor-pointer bg-persona-white mx-auto px-4 py-2 rounded-md shadow-lg group transition duration-100 hover:bg-persona">
                    <p className="flex flex-grow text-md font-bold text-persona group-hover:text-persona-white mr-5">Select .json file</p>
                    <BsCloudUpload className="text-3xl w-10 text-persona group-hover:text-persona-white h-full" />
                    <input className="hidden" type="file" accept="application/json" name="file" onChange={handleFileUpload} />
                </label>

                {
                    editableJsonData !== undefined ?
                        <div className="flex cursor-pointer bg-persona-white mx-auto px-4 py-2 rounded-md shadow-lg group transition duration-100 hover:bg-persona"
                            onClick={reloadAppWithData}>
                            <p className="flex text-md font-bold text-persona group-hover:text-persona-white text-center">Reload app with data</p>
                        </div>
                        : null
                }

                <div className="flex cursor-pointer bg-persona-white rounded-md shadow-lg group transition duration-100 hover:bg-persona">
                    <MdOutlineDelete onClick={clearLocalData} className="flex flex-grow text-2xl mx-2 h-full text-persona group-hover:text-persona-white text-center" />
                </div>
            </div>

            <textarea type='text' className="mt-5 h-full overflow-y-auto mb-5 mx-5 px-5 py-5 bg-persona-white rounded-md shadow-md text-xs"
                value={JSON.stringify(editableJsonData, null, '\t')} onChange={handleInputFieldChange} />

            {
                statusText !== '' ?
                    <p className="bg-persona-white p-2 px-5 mx-5 mb-5 text-xs rounded-xl shadow-lg">{statusText}</p> :
                    null
            }
        </div>
    )
}

export default React.memo(LiveJsonUpdate)
