import produce, { setAutoFreeze, current } from "immer";

var path = require('path');

const metadata = require('../metadata.json');
const baseName = path.basename(metadata.cleanedJson);
const generalStore = require('../Stores/store');

let jsonData = require('../jsonData/CleanedJsonData/' + baseName);

let models = [];

let flowFragments = [];
let dialogues = [];
let dialogueFragments = [];
let hubs = [];
let jumps = [];
let locations = [];
let conditions = [];
let entities = [];

let dialogHistory = []; // We store the dialog history ourselves, to accommodate loops and such.
let missionUpdateArray = [];

export let assets = [];
export let globalVariables = [];// cleanJsonData.GlobalVariables;  // an array of (nameSpace) objects

export const missions = getMissionArray();

export let allTypes = [];

export const getJsonData = () => {
    return jsonData;
}

export function getArrayByType(type) {

    return models.filter((list) => list.Type === type);
}

// Return only the array elements that are "mission" namespaces
export function getMissionArray() {
    return globalVariables.filter((mission) => mission.Namespace.toLowerCase().startsWith("mission"));
}

// (from the mission namespaces) find a particular mission
export function getMissionFromNamespace(namespace) {
    return getMissionArray().find((mission) => mission.Namespace === namespace);
}

export function getVariableWithNamespace(namespace) {
    return globalVariables.find((variable) => variable.Namespace.toString().toLowerCase() === namespace.toString().toLowerCase());
}

export const getSelectedSceneFromId = (id) => {
    
    if (id == null)
        return null;
    
    var idFound = null;

    for (const key in allTypes) {
        for (const entry in allTypes[key]) {
            var scene = allTypes[key][entry];
            
            if (scene.Properties.Id === undefined)
                continue;

            if (scene.Properties.Id === id) {
                idFound = scene;
                break;
            }
        }
    }
    if (idFound === null) {
        myConsoleLog("possible ERROR in getSelectedScene...: cannot find " + id);
    }
    return idFound;
}

export const resetDialogHistory = () => {
    dialogHistory = [];
    return true;
}

export const getDialogHistory = () => {
    return dialogHistory;
}
export const resetMissionUpdateArray = () => {
    missionUpdateArray = [];
    return true;
}

export const getMissionUpdateArray = () => {
    return missionUpdateArray;
}


export const getEntityWithSpeakerId = (id) => {
    return entities.find(entity => entity.Properties.Id === id);
}

export const getAssetWithId = (id) => {
    return assets.find(asset => asset.Properties.Id === id);
}

// Check whether a node with given id is present in a certain array
// N.B. The array is an array of nodes
export const isIDInArray = (id, anArray) => {
    var result = false;

    for (const index in anArray) {
        if (anArray[index].Properties.Id === id) {
            return true;
        }
    }
    return result;
}

export const isEntityPlayer = (id) => {

    const entity = getEntityWithSpeakerId(id);

    if (entity === undefined)
        return false;

    if (entity.Properties.DisplayName === "Player")
        return true;

    return false;
}

// returns a scene object (DialogueFragment) with the specified ID
function getSceneWhereOutputContainsId(id) {

    for (const i in dialogues) {
        for (const connection in dialogues[i].Properties.OutputPins[0].Connections) {

            if (dialogues[i].Properties.OutputPins[0].Connections[connection].Target === id) {
                return dialogues[i];
            }
        }
    }

    for (const i in dialogueFragments) {
        for (const connection in dialogueFragments[i].Properties.OutputPins[0].Connections) {

            if (dialogueFragments[i].Properties.OutputPins[0].Connections[connection].Target === id) {
                return dialogueFragments[i];
            }
        }
    }

    for (const i in hubs) {
        for (const connection in hubs[i].Properties.OutputPins[0].Connections) {

            if (hubs[i].Properties.OutputPins[0].Connections[connection].Target === id) {
                return hubs[i];
            }
        }
    }
    return null;
}

export const getDialogueFragmentWithInputTargetPinId = (targetPinId) => {

    for (const i in dialogueFragments) {
        if (dialogueFragments[i].Properties.InputPins[0].Id === targetPinId) {
            return dialogueFragments[i];
        }
    }

    for (const i in jumps) {
        if (jumps[i].Properties.InputPins[0].Id === targetPinId) {
            return jumps[i];
        }
    }

    return null;
}

// returns an array with the DialogueFragment with the specified ID and all nodes before to get to it.
export const getSceneHistoryArray = (id) => {

    myConsoleLog("Trying to get selected scene with id: " + id);

    var sceneArr = [];
    var currentNode = null;

    currentNode = getSelectedSceneFromId(id);

    if (currentNode === null) {
        myConsoleLog("Id : " + id + " not found!");
        return null;
    }
    else {
        sceneArr.push(currentNode);
    }

    while (currentNode !== null && (currentNode.Type === 'DialogueFragment' || currentNode.Type === 'Hub' || currentNode.Type === 'Dialogue')) {

        var previousNode = getSceneWhereOutputContainsId(currentNode.Properties.Id);
        if (previousNode !== null) {
            currentNode = previousNode;
            sceneArr.unshift(currentNode);
        }
        else
            currentNode = null;
    }

    return sceneArr;
}

// Global Variable wrangling
export const getGlobalVariables = () => {
    return globalVariables;
}

export const overrideGlobalVariables = (overrideGlobalVars) => {
    globalVariables = overrideGlobalVars;

    //console.log('overridden global variables!', globalVariables);
}

export const getVariableWithName = (nameSpace, varName) => {

    if (nameSpace === "")
        return globalVariables.find(entry => entry.Namespace.toLowerCase() === varName.toLowerCase());
    
    // First find the Namespace
    let nSpace = globalVariables.find(entry => entry.Namespace.toLowerCase() === nameSpace.toLowerCase());

    if (nSpace === undefined)
        return undefined;
    
    // then try to find the variable
    return nSpace.Variables.find(element => element.Variable.toLowerCase() === varName.toLowerCase())
}

export const getNodeWithExternalId = (externalId) => {
    const node = flowFragments.find(e => e.Properties.ExternalId === externalId);
    
    return node;
}

export const updateGlobalVar = (key, newValue, trail) => {
    
    setAutoFreeze(false)
    let updatedGlobalVars = produce(globalVariables, draft => {

        digDeeper(draft, trail, key, newValue);
    })

    globalVariables = updatedGlobalVars;
    return updatedGlobalVars;
}

export const updateGlobalVarByNamespace = (namespace, newValue) => {
    
    setAutoFreeze(false);
    let updatedGlobalVars = produce(globalVariables, draft => {
        const index = draft.findIndex(e => e.Namespace === namespace);
        if (index !== -1)
            draft[index] = newValue;
    });

    globalVariables = updatedGlobalVars;
    return updatedGlobalVars;
}

const digDeeper = (draft, trail, key, newValue) => {

    if (trail != null && trail.length > 0) {
        const dugNode = draft.filter(a => a.Namespace === trail[0]);
        
        if (dugNode == null)
            return;
        
        trail.shift();
        digDeeper(dugNode, trail, key, newValue);
    }
    else {
        const type = draft[0].Variables.filter(a => a.Variable === key)[0].Type;
        switch (type) {
            case "Boolean":
                const parsed = newValue === true ? 'true' : "false";
                draft[0].Variables.filter(a => a.Variable === key)[0].Value = parsed;
                break;
                
            case "String":
                draft[0].Variables.filter(a => a.Variable === key)[0].Value = newValue.toString();
                break;
                
            case "Integer":
                draft[0].Variables.filter(a => a.Variable === key)[0].Value = newValue;
                break;
        }
    }
}

// check a variable sting like 'mission_01_purpleguy.step4_playerispurple==true'
// to see if it has the same value in the global variables database
//
// The format is: namespace.variableName operator [value]
// N.B. assume the string has alreadty been converted to lowerCase
// 
export const checkVarCond = (varString) => {

    // First split the variable on the operator
    let varCondResult = findValidOperator(varString, ["!=", ">=", "<=", "==", ">", "<"])

    if (varCondResult.position === -1) {
        myConsoleLog("ERROR: no valid operator found in " + varString);
        return undefined;   // no valid operator found
    }
    let splitOnOperator = varString.split(varCondResult.operator);
    // Then, split the first part on .
    let splitString = splitOnOperator[0].split(/\./);

    let varChunk = getVariableWithName(splitString[0], splitString[1]);
    if (varChunk === undefined)
        return undefined;

    myConsoleLog('checking variable ' + varChunk.Variable + ' w value ' + varChunk.Value + ' against value ' + splitOnOperator[1]);
    // Depending on the operator, evaluate the expression
    if ((varCondResult.operator === ">=") && (parseInt(varChunk.Value) >= parseInt(splitOnOperator[1]))) {
        return true;
    }
    else if ((varCondResult.operator === "<=") && (parseInt(varChunk.Value) <= parseInt(splitOnOperator[1]))) {
        return true;
    }
    else if ((varCondResult.operator === "!=") && (varChunk.Value.toLowerCase() !== splitOnOperator[1])) {
        // @@@ Gidi: not sure if this works correctly for integer values
        return true;
    }
    else if ((varCondResult.operator === "==") && (varChunk.Value.toLowerCase() === splitOnOperator[1])) {
        return true;
    }
    else if ((varCondResult.operator === ">") && (parseInt(varChunk.Value) > parseInt(splitOnOperator[1]))) {
        return true;
    }
    else if ((varCondResult.operator === "<") && (parseInt(varChunk.Value) < parseInt(splitOnOperator[1]))) {
        return true;
    }

    return false;
}

// Try to find a valid opearator in a varConditionString
// Valid operators are listed in an array of strings
// N.B. the operators are searched in order! So if you want to distinguish between
// > and >=, you should search for the more specific one (>=) first!
//
// return multiple values:
//  position = index of the first operator, or -1 if no valid operator can be found
//  operator = a string describing the first operator found.
const findValidOperator = (conditionString, operatorArray) => {

    for (const index in operatorArray) {
        let operatorName = operatorArray[index];
        let result = conditionString.indexOf(operatorName)
        if (result !== -1) {
            // operator found, return position and operatorName
            return {
                position: result,
                operator: operatorName
            }
        }
    }
    return {
        position: -1,
        operator: ""
    }
}
// The same as checkVarCond, but also checks && conditions, || conditions and () brackets
// E.g. mission_01_purpleguy.step3_foundpurplepaint==true
// &&
// mission_01_purpleguy.step4_playerispurple==false

export const checkCondition = (varString) => {
    if (varString === null) {
        return true;
    }
    // FIrst clean the string
    let tempString = cleanString(varString);

    // if the string starts with a (, find the closing bracket:
    if (tempString[0] === "(") {
        let matchingPos = findClosingBracketMatchIndex(tempString, 0);
        if (matchingPos === -1) {
            myConsoleLog("ERROR: no closing bracket found in  " + tempString);
        }
        else {
            // It is a compound condition, or a compound condition followed by a || or &&
            let newString = tempString.substring(1, matchingPos);    // strip the brackets.
            let result1 = checkCondition(newString);    // evaluate this substring
            if ((matchingPos + 1) === tempString.length) {
                return result1; // the result of the compound is the result from within the brackets
            }
            else if ((tempString[matchingPos + 1] === "|") && (tempString[matchingPos + 2] === "|")) {
                // compound followed by || condition and another compound
                if (result1) {
                    return true;    // no need to investigate further
                }
                else {
                    // the rest of the string determines the outcome
                    return checkCondition(tempString.substring(matchingPos + 3));
                }
            }
            else if ((tempString[matchingPos + 1] === "&") && (tempString[matchingPos + 2] === "&")) {
                // compound followed by && condition and another compound
                if (!result1) {
                    return false;    // no need to investigate further
                }
                else {
                    // the rest of the string determines the outcome
                    return checkCondition(tempString.substring(matchingPos + 3));
                }
            }
            else {
                myConsoleLog("ERROR: no proper end of  " + tempString);
                return false;
            }
        }
    }
    else {
        // The condition is variableCondition, or a variableCondition followed by a || or a &&
        let firstOr = tempString.indexOf("||");
        let firstAnd = tempString.indexOf("&&");
        if ((firstAnd !== -1) && (firstOr !== -1)) {
            // both exist, use the first one!
            if (firstAnd < firstOr) {
                // And condition is first
                let newString = tempString.substring(0, firstAnd);    // strip the rest
                let result1 = checkCondition(newString);    // evaluate this substring
                if (!result1) {
                    return false;    // no need to investigate further
                }
                else {
                    // the rest of the string determines the outcome
                    return checkCondition(tempString.substring(firstAnd + 2));
                }
            }
            else {
                // Or condition
                let newString = tempString.substring(0, firstOr);    // strip the rest
                let result1 = checkCondition(newString);    // evaluate this substring
                if (result1) {
                    return true;    // no need to investigate further
                }
                else {
                    // the rest of the string determines the outcome
                    return checkCondition(tempString.substring(firstOr + 2));
                }
            }
        }
        else if (firstAnd !== -1) {
            // the And condition exists
            let newString = tempString.substring(0, firstAnd);    // strip the rest
            let result1 = checkCondition(newString);    // evaluate this substring
            if (!result1) {
                return false;    // no need to investigate further
            }
            else {
                // the rest of the string determines the outcome
                return checkCondition(tempString.substring(firstAnd + 2));
            }
        }
        else if (firstOr !== -1) {
            // the Or condition exists
            let newString = tempString.substring(0, firstOr);    // strip the rest
            let result1 = checkCondition(newString);    // evaluate this substring
            if (result1) {
                return true;    // no need to investigate further
            }
            else {
                // the rest of the string determines the outcome
                return checkCondition(tempString.substring(firstOr + 2));
            }
        }
        else {
            // both do not exist, we expect a single variable condition
            return checkVarCond(tempString);
        }
    }
}

// Make a "clean" condition
//
export const cleanString = (aString) => {
    // First strip newlines from the string
    let strippedString = aString.replace(/\r?\n|\r/gm, "");
    // Then strio all whitespace
    strippedString = strippedString.replace(/\s/g, "");
    // Then convert to lowercase
    strippedString = strippedString.toLowerCase();
    // Extra: if the string starts with if(, strip the if
    strippedString = strippedString.replace(/^if\(/, "("); // strip the "if" from the start
    // Then strip leading and trailing whitespace
    // strippedString = strippedString.replace(/^\s|\s$/g, "");
    // then remove whitespace before or after brackets
    //strippedString = strippedString.replace(/\s\(|\(\s/g, "(");
    //strippedString = strippedString.replace(/\s\)|\)\s/g, ")");

    return strippedString;
}

// From https://codereview.stackexchange.com/questions/179471/find-the-corresponding-closing-parenthesis
//
function findClosingBracketMatchIndex(str, pos) {
    if (str[pos] !== '(') {
        //throw new Error("No '(' at index " + pos);
        myConsoleLog("ERROR: expect opening bracket ( at pos " + pos);
    }
    let depth = 1;
    for (let i = pos + 1; i < str.length; i++) {
        switch (str[i]) {
            case '(':
                depth++;
                break;
            case ')':
                if (--depth === 0) {
                    return i;
                }
                break;
        }
    }
    return -1;    // No matching closing parenthesis
}

// sets a variable with an articy string like: 'mission_01_purpleguy.step4_playerispurple=true'
// mission_01_purpleguy.step4_playerispurple=true
export const setVariable = (varString) => {

    const sanitizedStr = cleanString(varString);  //varString.replace(/(\r\n|\n|\r)/gm, "");   // do a cleanString
    let multipleVarString = sanitizedStr.split(';');

    myConsoleLog(multipleVarString.length + " variable string(s) detected!");

    for (let i = 0; i < multipleVarString.length; i++) {
        // for each of the variables to set,

        // First find the operator and split the string on the operator
        let operatorResult = findValidOperator(multipleVarString[i], ["++", "--", "+=", "-=", "="]);
        if (operatorResult.position === -1) {
            myConsoleLog("ERROR: no valid operator found in " + multipleVarString[i]);
            continue;
        }
        // split on the operator
        let splitOnOperator = multipleVarString[i].split(operatorResult.operator);
        // Then, split the first part on .
        let splitString = splitOnOperator[0].split(/\./);

        // First find the Namespace
        let nSpace = globalVariables.find(entry => entry.Namespace.toLowerCase() === splitString[0]);
        if (nSpace === undefined) {
            myConsoleLog("Cannot find namespace <" + splitString[0] + ">")
            return false;
        }
        // then try to find the variable
        let varChunk = nSpace.Variables.find(element => element.Variable.toLowerCase() === splitString[1])

        if (varChunk === undefined) {
            myConsoleLog("Cannot find variable <" + splitString[1] + ">")
            return false;
        }
        var index = nSpace.Variables.indexOf(varChunk);

        // Depending on the operator, set the value to a new one
        if (operatorResult.operator === "++") {
            nSpace.Variables[index].Value = (parseInt(nSpace.Variables[index].Value) + 1).toString();
        }
        else if (operatorResult.operator === "--") {
            nSpace.Variables[index].Value = (parseInt(nSpace.Variables[index].Value) - 1).toString();
            myConsoleLog(nSpace.Namespace + ': decreasing var ' + varChunk.Variable + ' at index ' + index + ' by 1, to ' + nSpace.Variables[index].Value.toString());

        }
        else if (operatorResult.operator === "+=") {
            nSpace.Variables[index].Value = (parseInt(nSpace.Variables[index].Value) + parseInt(splitOnOperator[1])).toString();
        }
        else if (operatorResult.operator === "-=") {
            nSpace.Variables[index].Value = (parseInt(nSpace.Variables[index].Value) - parseInt(splitOnOperator[1])).toString();
        }
        else if (operatorResult.operator === "=") {
            nSpace.Variables[index].Value = splitOnOperator[1];
            myConsoleLog(nSpace.Namespace + ': setting var ' + varChunk.Variable + ' at index ' + index + ' to ' + splitOnOperator[1]);
        }

    }

    return true;
}

// --------------------------------------------------------------------------

// A pinELement has an (optional) Text (= condition or veriable setting)
// an id
// an owner
// (optional) connections
//

// Function returns a pinElement if the model with Id = ownerID 
// contains an outputPin with given id pinID, null otherwise
export const hasOutputPin = (ownerID, pinID) => {
    for (const index in models) {
        if ((models[index].Properties !== undefined) && (models[index].Properties !== null)) {
            if (models[index].Properties.Id === ownerID) {
                for (const pinIndex in models[index].Properties.OutputPins) {
                    var element = models[index].Properties.OutputPins[pinIndex];
                    if (element.Id === pinID) {
                        return element;
                    }
                }
            }
        }
    }
    return null;
}

// Function returns a pinElement if the model with Id = ownerID 
// contains an inputPin with given id pinID, null otherwise
export const hasInputPin = (ownerID, pinID) => {
    for (const index in models) {
        if ((models[index].Properties !== undefined) && (models[index].Properties !== null)) {
            if (models[index].Properties.Id === ownerID) {
                for (const pinIndex in models[index].Properties.InputPins) {
                    var element = models[index].Properties.InputPins[pinIndex];
                    if (element.Id === pinID) {
                        return element;
                    }
                }
            }
        }
    }
    return null;
}

// Function to fund the full pinElement for a given pinID
export const findPinElement = (pinID, context = "") => {

    //myConsoleLog("context is " + context + " pinID " + pinID);
    for (const index in models) {
        if ((models[index].Properties !== undefined) && (models[index].Properties !== null)) {
            // check outputPins
            for (const pinIndex in models[index].Properties.OutputPins) {
                var element = models[index].Properties.OutputPins[pinIndex];
                if (element.Id === pinID) {
                    return element;
                }
            }
            // check inputPins
            for (const pinIndex in models[index].Properties.InputPins) {
                var element2 = models[index].Properties.InputPins[pinIndex];
                if (element2.Id === pinID) {
                    return element2;
                }
            }
        }
    }
    myConsoleLog("PROBLEM: PINELEMENT CANNOT FIND " + pinID);
    return null;
}

// Given a pinId, find the (first) inputPin and return the pinELement
//
export const findInputPin = (elementId) => {

    for (const index in models) {
        if ((models[index].Properties !== undefined) && (models[index].Properties !== null)) {
            if (models[index].Properties.Id === elementId) {
                // check inputPins
                for (const pinIndex in models[index].Properties.InputPins) {
                    return models[index].Properties.InputPins[pinIndex];
                }
            }
        }
    }
    return null;
}

// check whether the condition for this (input) pin is satisfied
//
export const checkPinCondition = (pinID) => {
    var anElement = findPinElement(pinID);  //, "checkPinCondition");

    if ((anElement !== null) && (hasInputPin(anElement.Owner, anElement.Id) !== null)) {
        // if it is an inputPin
        if (anElement.Text !== "") {
            return checkCondition(anElement.Text);   // true, false or undefined
        }
        else
            return true;    // empty Text are by definition true?
    }
    else
        return undefined;   // do no evaluate conditions in outputPins
}

// set a variable for this (output) pin as instructed in the Text
//
export const setOutputPinVariable = (pinID) => {
    var anElement = findPinElement(pinID);  //, "setOutputPinVariable");

    if ((anElement !== null) && (hasOutputPin(anElement.Owner, anElement.Id) !== null)) {
        // if it is an outputPin
        if (anElement.Text !== "") {
            myConsoleLog('Setting variable for pin ' + pinID);
            return setVariable(anElement.Text);   // true, false or undefined
        }
        else
            return true;    // empty Text are by definition true?
    }
    else
        return undefined;   // do no evaluate variables in inputPins
}
// Check whether a node with given id is present in a certain array
// N.B. The array is an array of just Ids
export const isIDInPinArray = (id, anArray) => {
    var result = false;

    for (const index in anArray) {
        if (anArray[index] === id) {
            return true;
        }
    }
    return result;
}

// Function to fund the ID given a DisplayName
// (To find the Player, use findIDGivenName("PLayer"))
export const findIDGivenName = (givenName, toLowercase, removeSpaces) => {
    //console.log(models);

    for (const index in models) {
        if ((models[index].Properties !== undefined) && (models[index].Properties !== null)) {
            let displayName = models[index].Properties.DisplayName;

            if (displayName === undefined)
                continue;

            if (toLowercase === true)
                displayName = displayName.toLowerCase();
            if (removeSpaces === true)
                displayName = displayName.replace(' ', '');

            if (displayName !== givenName)
                continue;

            return models[index].Properties.Id;
        }
    }
    return null;
}

// Return true if the scene fragment associated with this node is a 
// dialogueFragment for a given speakerID
// var node = getSelectedSceneFromId(id);
export const isSpeakerAction = (node, speakerID) => {
    if (node !== null) {
        if ((node.Type === "DialogueFragment") &&
            (node.Properties.Speaker === speakerID)) {
            return true;
        }
        else {
            return false;
        }
    }
    else
        return false;
}

// Return true if the scene fragment associated with this node is a 
// dialogueFragment for a given speakerID
export const isPlayerAction = (node) => {
    if (node !== null) {
        if ((node.Type === "DialogueFragment") &&
            (node.Properties.Speaker === findIDGivenName("Player", false, false))) {
            return true;
        }
        else {
            return false;
        }
    }
    else
        return false;
}

// place a custom message in the current history
export const addCustomMessage = (messageText) => {

    let customMsgNode = {
        Type: "DialogueFragment",
        Properties: {}
    };

    customMsgNode.Properties = {
        Id: "inventory_pick_result",
        MenuText: "Je gekozen opties zijn",
        Text: messageText,
        Speaker: "0x0100000000000116" // id of player
    };

    myDialogHistoryPush(customMsgNode);
}

//
// Execute a player action, and determine at what node the resulting state is going to be
//
export const executeNewPlayerAction = (currentNode, display = true) => {

    if (currentNode === null) {
        return null;
    }

    // 1] Push the current action on the dialog history.
    if (display === true)
        myDialogHistoryPush(currentNode);

    // for each of the output pins (expect only 1)
    let outArray = currentNode.Properties.OutputPins;
    // putArray is an array of pinElements
    let deeperResult = findDeeperNode(currentNode.Properties.Id, outArray);

    // Now examine the results
    if ((deeperResult === null) || (deeperResult === undefined)) {
        // the current path cannot continue, and must end at the current node
        return { sceneId: currentNode.Properties.Id, sceneType: "scene" };
    }
    //myConsoleLog("Result = " + deeperResult);
    return deeperResult;
}

const sanitizeString = (str) => {
    return str.replace(" ", "").toLowerCase();
}

// Execute actions that are not Player actions, until you find a player action 
// It either gives null back (when there is no feasible scene along this path)
// or an object with sceneType and sceneId
//  
export const executeNPCAction = (currentNode) => {
    let result = null;

    if (currentNode === null) {
        return result;
    }

    else if ((currentNode.Type === "Dialogue") || (currentNode.Type === "FlowFragment")) {
        // first determine a good starting point in this node

        const displayName = sanitizeString(currentNode.Properties.DisplayName);
        if (currentNode.Type === "FlowFragment") {
            if (displayName === 'setheader') {

                const imagePath = findImageForElement(currentNode);
                if (imagePath != null)
                    generalStore.useGeneralStore.setState({ headerImagePath: imagePath });
                generalStore.useGeneralStore.setState({ headerText: currentNode.Properties.Text });
            }
            else if (displayName === 'clearheader') {
                generalStore.useGeneralStore.setState({
                    headerText: "",
                    headerImagePath: null
                });
            }
        }

        let resultArr = determineStartingPoint(currentNode.Properties.Id);  // @@@ replace Id with node?
        if (resultArr === null) {
            myConsoleLog("ERROR: node " + currentNode.Properties.Id + " has no good starting point");
            return result;  // error goes to null
        }

        // eliminate the starting points which are Player Actions, or which have a condition which is false
        let deeperResultArr = [];
        for (const index in resultArr) {
            // @@@3
            let deeperNode = getSelectedSceneFromId(resultArr[index]);
            if (!isPlayerAction(deeperNode)) {
                let pinElement = findInputPin(deeperNode.Properties.Id);
                if (checkPinCondition(pinElement.Id) !== false) {
                    deeperResultArr.push(deeperNode);
                }   // else, ignore this branch    
            } // else, ignore the branch
        }

        // Now there should be one branch left.
        if ((deeperResultArr === null) || (deeperResultArr === undefined) || (deeperResultArr.length === 0)) {
            //myConsoleLog("No branches left in executeNPCAction");
            return result;  // error goes to null            
        }
        else if (deeperResultArr.length > 1) {
            myConsoleLog("WARNING: multiple branches left in executeNPCAction");
        }
        // just take the first one?
        // 
        // Dive into the Dialogue or FlowFragment to execute NPC actions
        return executeNPCAction(deeperResultArr[0]);
    }
    // hub nodes can be ignored, unless it is directly related to a push button?
    else if (currentNode.Type === "Hub") {
        if (currentNode.Properties.DisplayName.toLowerCase() === "player hub") {
            // myConsoleLog("XXX Going to player Hub " + currentNode.Properties.Id);
            return { sceneId: currentNode.Properties.Id, sceneType: "/" };
        }
        else {
            // @@@ Check condition?
            // @@@ Set output variable?

            // for each of the output pins (expect only 1)
            let outArray = currentNode.Properties.OutputPins;
            // outArray is an array of pinElements
            let deeperResult = findDeeperNode(currentNode.Properties.Id, outArray);   // try to follow branches

            // Now examine the results
            if ((deeperResult === null) || (deeperResult === undefined)) {
                // the current path cannot continue, and must end at the current node
                return { sceneId: currentNode.Properties.Id, sceneType: "scene" };
            }
            // 1  or more ... just take the first one?
            //myConsoleLog("Returning deeper result " + deeperResult);
            return deeperResult;
        }
    }
    else if (currentNode.Type === "Condition") {
        // condition checking has already been done, but no problem checking again
        // if this is a player action, or there are conditions and the conditions are not true
        if (checkPinCondition(findInputPin(currentNode.Properties.Id).Id) === false) {
            // no need to search further along this branch
            myConsoleLog("Condition FALSE encountered on inputPin of " + currentNode.Properties.Id);
            return null;
        }
        else {
            // the conditions on the input pin are true or undefined   
            myConsoleLog("NPCAction currentNode CONDITION is now " + currentNode.Properties.Id);

            let conditionString = currentNode.Properties.Expression;

            if ((conditionString !== null) && (conditionString !== "")) {
                conditionString = conditionString.replace(/^if/, ""); // strip the "if" from the start
                var outArray = currentNode.Properties.OutputPins;
                var selectedPin = null;

                if (checkCondition(conditionString)) {
                    // follow the one output pin
                    selectedPin = outArray[0];
                }
                else {
                    // follow the other pin
                    selectedPin = outArray[1];
                }
                let deeperResult = findDeeperNode(currentNode.Properties.Id, [selectedPin]);   // try to follow branches
                return deeperResult;
            }
            else {
                myConsoleLog("WARNING: Condition in Condition node is empty?");
                return null;
            }
        }
    }
    else if (currentNode.Type === "Jump") {
        //myConsoleLog("Doing Jump", true);
        var targetPin2 = currentNode.Properties.TargetPin;
        if (checkPinCondition(targetPin2) !== false) {
            let pinElem = findPinElement(targetPin2);
            if (pinElem !== null) {
                return executeNPCAction(getSelectedSceneFromId(pinElem.Owner));
            }
        }
        return null;
    }
    else if (currentNode.Type === "DialogueFragment") {
        // condition checking has already been done, but no problem checking again
        // if this is a player action, or there are conditions and the conditions are not true
        if (isPlayerAction(currentNode) || (checkPinCondition(findInputPin(currentNode.Properties.Id).Id) === false)) {
            // no need to search further along this branch
            myConsoleLog("PlayerAction or condition encountered " + currentNode.Properties.Id);
            return null;
        }
        else {
            // Not a player action, and the conditions are true or undefined
            // 1] Push the current action on the dialog history.
            myDialogHistoryPush(currentNode);
            myConsoleLog("NPCAction currentNode is now " + currentNode.Properties.Id);

            // for each of the output pins (expect only 1)
            let outArray = currentNode.Properties.OutputPins;
            // outArray is an array of pinElements
            let deeperResult = findDeeperNode(currentNode.Properties.Id, outArray);   // try to follow branches

            // Now examine the results
            if ((deeperResult === null) || (deeperResult === undefined)) {
                // the current path cannot continue, and must end at the current node
                return { sceneId: currentNode.Properties.Id, sceneType: "scene" };
            }
            // 1  or more ... just take the first one?
            //myConsoleLog("Returning deeper result " + deeperResult);
            return deeperResult;
        }
    }
    else {
        myConsoleLog("ERROR: unknown node type in executeNPCAction: " + currentNode.Type);
        return result;  // error goes to null           
    }
}


// Given an output pin, find all target pins for the connections from this
// output pin where the conditions are undefined or true.
// return an array of pinIDs
export const findTargetPinsFromOutputPin = (pinId) => {
    var anElement = findPinElement(pinId);  //, "findTargetPinsFrom ..");
    var resultArr = [];

    if (anElement === null)
        return resultArr;   // empty array

    //myConsoleLog("Elem = " + anElement.Id + " con length " + anElement.Connections.length);

    for (const connection in anElement.Connections) {
        var newPinId = anElement.Connections[connection].TargetPin;
        //if all conditions at the input pins of this node are true or undefined
        if (checkPinCondition(newPinId) !== false) {
            //myConsoleLog("Found suitable target pin " + newPinId);
            resultArr.push(newPinId);
        }
    }
    return resultArr;
}

// ExceuteNPCAction along a deeper branch (starting from an array of pinELements)
// 
const findDeeperNode = (currentID, pinArray) => {
    //myConsoleLog("FindDeeperNode " + currentID + " array " + pinArray.length + " El 0= " + pinArray[0].Id);
    let deeperResultArray = [];

    for (const aPin in pinArray) {
        setOutputPinVariable(pinArray[aPin].Id); // 2] process variables in the output pins

        // if the pinELement is an output pin
        let tempResult = findTargetPinsFromOutputPin(pinArray[aPin].Id);    // return only pin ids with conditions true or undefined
        for (const newPin in tempResult) {
            let pinElement = findPinElement(tempResult[newPin]);    //, "findDeeperNode");   
            // investigate whether an input or output pin 

            // if the pin represents an inputPin
            if (hasInputPin(pinElement.Owner, pinElement.Id) !== null) {
                let newNode = getSelectedSceneFromId(pinElement.Owner);
                // investigate this branch
                let tempResult2 = executeNPCAction(newNode);
                if ((tempResult2 !== null) && (tempResult2 !== undefined)) {
                    //myConsoleLog("Pushing non-null result for " + newNode.Properties.Id);
                    deeperResultArray.push(tempResult2);
                }
            }
            else if (hasOutputPin(pinElement.Owner, pinElement.Id) !== null) {
                // If it is an outputPin, add the targetpins to the list of pins to examine
                //myConsoleLog("3b] pin = " + pinElement.Id + " is an output pin");

                let newResult = findDeeperNode(pinElement.Owner, [pinElement]);   // use the current node as starting point
                if ((newResult !== null) && (newResult !== undefined)) {
                    //myConsoleLog("Pushing non-null result for output pins");
                    deeperResultArray.push(newResult);
                }
            }
            else {
                myConsoleLog("Error! Neither input nor output! " + pinElement.Id);
            }
        }
    }
    // Now examine the results
    if ((deeperResultArray === null) || (deeperResultArray === undefined) || (deeperResultArray.length === 0)) {
        // the current path cannot continue, and must end at the current node
        return { sceneId: currentID, sceneType: "scene" };
    }
    else if (deeperResultArray.length > 1) {
        myConsoleLog("WARNING: multiple branches left in executeNPCAction");
    }
    // 1  or more ... just take the first one?
    // myConsoleLog("Returning deeper result " + deeperResultArray[0]);
    return deeperResultArray[0];
}

// Push this node on the dialogHistory, and do additional actions for certain
// dialogueFragments
//
export const myDialogHistoryPush = (aNode) => {
    
    if (aNode === null) {
        myConsoleLog("Potential Error in myDialogHistoryPush! node is null");
    }
    else if (aNode.Type !== "DialogueFragment") {
        myConsoleLog("Potential Error in myDialogHistoryPush! node " + aNode.Properties.Id + " Type is " + aNode.Type);
    }
    else {
        // push on the dialogHistory array
        dialogHistory.push(aNode);

        // also, depending on the type, ypu could do some more actions:
        const entity = getEntityWithSpeakerId(aNode.Properties.Speaker);
        if (entity === undefined) {
            myConsoleLog("Error! DialogueFragment has unknown speaker Id:" + aNode.Properties.Speaker);
        }
        else {
            // valid entity for DialogueFragment
            if (entity.Properties.DisplayName === "Mission update") {
                myConsoleLog("Mission update found!");
                missionUpdateArray.push(aNode);
            }
            // @@@ Else .... could be used for descriptions?
        }
    }
}

// based on a valid scene node, determine what player actions immediately follow it
export const determineNewActionArray = (currentNode) => {
    var actionArr = [];     // output array

    myConsoleLog("determine new actions from " + currentNode.Properties.Id);

    if ((currentNode.Properties === undefined) || (currentNode.Properties === null)) {
        myConsoleLog("ERROR: determineNewActionArray has no valid starting point");
    }
    else {
        // The currentNode is also an option??? Only when not yet the last element of the dialogHistory!
        if (isPlayerAction(currentNode) && !isLastElementOf(dialogHistory, currentNode)) {
            actionArr.push(currentNode);
        }

        var outArray = currentNode.Properties.OutputPins;
        // Normally, there should be only 1 output pin. Except with Condition nodes, there are 2!

        // if the currentNode is a condition node, we should only use one of the of the pins, depending on the condition.
        if (currentNode.Type === "Condition") {
            let conditionString = currentNode.Properties.Expression;
            if ((conditionString !== null) && (conditionString !== "")) {
                conditionString = conditionString.replace(/^if/, ""); // strip the "if" from the start

                if (checkCondition(conditionString)) {
                    // follow the one output pin
                    outArray = ((outArray !== null) && (outArray !== undefined)) ? [outArray[0]] : [];
                }
                else {
                    // the other pin
                    outArray = ((outArray !== null) && (outArray !== undefined)) ? [outArray[1]] : [];
                }
            }
            else {
                myConsoleLog('POSSIBLE ERROR: empty condition expression in ' + currentNode.Properties.Id);
            }
        }
        // else, we could limit the outArray to 1 pin ...

        for (const aPin in outArray) {
            //myConsoleLog("Output pin is " + outArray[aPin].Id);
            var tempResult = findTargetPinsFromOutputPin(outArray[aPin].Id);
            for (const newPin in tempResult) {
                //myConsoleLog("Targetpin is " + tempResult[newPin]);
                var pinElement = findPinElement(tempResult[newPin]);
                //myConsoleLog("Owner is " + pinElement.Owner);  
                var newNode = getSelectedSceneFromId(pinElement.Owner);

                if (isPlayerAction(newNode)) {
                    actionArr.push(newNode);
                }
            }
        }
    }
    return actionArr;
}

// return true if (the id of) aNode is equal to (the id of) the last element 
// in an array of nodes
const isLastElementOf = (anArray, aNode) => {
    if ((anArray === null) || (anArray.length < 1) || (aNode === null)) {
        return false;
    }
    else {
        let lastElement = anArray[anArray.length - 1];
        if (lastElement.Properties.Id === aNode.Properties.Id) {
            return true;
        }
        else
            return false;
    }
}

// For nodes which are Entities or FlowFragments (or Dialogues or DialogueFragments)
// this function returns a relative path to the image associated
export const findImageForElement = (aNode) => {
    let resultImage = null;

    let assetID = aNode.Properties.PreviewImage.Asset;

    let asset = getAssetWithId(assetID);

    if (asset != null) {
        resultImage = asset.AssetRef;
    }

    //console.log(aNode);

    return resultImage;
}
// Get Location and Dialogue header
// Given a scene id (Dialogue Fragment Id), return a description for the current dialogue 
// and for the current location
// So we search upward in the hierarchy for the first ancestor which is a Dialogue, 
// and the first ancestor which is a FlowFragment
export const determineLocationAndContext = (sceneId) => {

    var scene = getSelectedSceneFromId(sceneId);

    if (scene === null)
        return null;

    var dialogId = findAncestor(sceneId, "Dialogue");
    var flowId = findAncestor(sceneId, "FlowFragment");

    var image = null

    var dialogDisplayName = null
    var dialogImage = null // an image associated with the dialog
    if (dialogId !== null) {
        var scene1 = getSelectedSceneFromId(dialogId);
        if (scene1 !== null) {
            //myConsoleLog("Dialogue CONTEXT found:" + dialogId);               
            dialogDisplayName = scene1.Properties.DisplayName;
            image = findImageForElement(scene1);
        }
        else {
            myConsoleLog("No Dialogue CONTEXT found:");
        }
    }

    var flowFragmentDisplayName = null
    var flowFragmentImage = null // an image associated with the flowfragment
    if (flowId !== null) {
        var scene2 = getSelectedSceneFromId(flowId);
        if (scene2 !== null) {
            //myConsoleLog("FlowFragment CONTEXT found:" + flowId);
            flowFragmentDisplayName = scene2.Properties.DisplayName;
            image = image ?? findImageForElement(scene2);
        }
        else {
            myConsoleLog("No FlowFragment CONTEXT found:");
        }
    }
    // choose the image based on the dialogue, but if not available, use the image from the flowfragment

    const result = {
        location: flowFragmentDisplayName,
        locationImage: image,
        context: dialogDisplayName
    };
    //console.log(result)

    return result;
}

// Find the first ancestor of a given type
export const findAncestor = (sceneId, sceneType) => {
    var scene = getSelectedSceneFromId(sceneId);

    if (scene === null)
        return null;

    if (scene.Type === sceneType) {
        return sceneId;
    }
    else {
        return findAncestor(scene.Properties.Parent, sceneType);   // search higher in the tree
    }

}

// Find the first ancestor of a given type with attachments 
export const findAncestorWithAttachments = (sceneId, sceneType) => {
    var scene = getSelectedSceneFromId(sceneId);

    if (scene === null)
        return null;

    if (scene.Type === sceneType) {
        let attachArr = scene.Properties.Attachments;
        if ((attachArr !== undefined) && (attachArr.length > 0)) {
            // there is an attachment Array and it has elements
            return sceneId;
        }
        else {
            // no attachments, keep lookng higher in the tree
            return findAncestor(scene.Properties.Parent, sceneType);   // search higher in the tree
        }
    }
    else {
        return findAncestor(scene.Properties.Parent, sceneType);   // search higher in the tree
    }
}

// Given a scene id, return a list of attachments which are relevant for this scene
export const findAttachments = (sceneId) => {
    // find a flowFragment Id with attachments
    var flowId = findAncestorWithAttachments(sceneId, "FlowFragment");

    if ((flowId === undefined) || (flowId === null))
        return [];  // return an empty array
    else {
        // the scene has attachments!
        let flowfrag = getSelectedSceneFromId(flowId);

        return flowfrag.Properties.Attachments; // a non-empty array of ids
    }
}

// return true if attachmentId is present in the attachments of the current scene
// or its ancestors,
// false otherwise
export const isIdAttached = (sceneId, attachmentId) => {
    let attachArr = findAttachments(sceneId);

    if ((attachArr !== null) && (attachArr.indexOf(attachmentId) !== -1))
        return true;
    else
        return false;
}

// Based on an Id in the JSON data, determine what would be a good starting
// point for the display of this scene.
// N.B. does not work for InputPins or OutputPins!
export const determineStartingPoint = (sceneId) => {
    var scene = getSelectedSceneFromId(sceneId);
    var result = [];

    if (scene === null) {
        myConsoleLog("ERROR STARTING POINT: SceneId is not a known scene?: " + sceneId);
        return null;
    }

    // depending on the type of the scene, determine what to do
    if ((scene.Type === "FlowFragment") || (scene.Type === "Dialogue")) {

        const displayName = sanitizeString(scene.Properties.DisplayName)
        if (displayName === 'setheader') {

            const imagePath = findImageForElement(scene);
            if (imagePath != null)
                generalStore.useGeneralStore.setState({ headerImagePath: imagePath });
            generalStore.useGeneralStore.setState({ headerText: scene.Properties.Text });
        }
        else if (displayName === 'clearheader') {
            generalStore.useGeneralStore.setState({
                headerText: "",
                headerImagePath: null
            });
        }

        // first determine how many suitable children there are.
        // A suitable child is the target of a connection from an inputPin,
        // and any conditions on this target are true at the moment.
        // return an array of suitable children
        var pinElement = findInputPin(sceneId);
        if (pinElement === null) {
            myConsoleLog("ERROR STARTING POINT : SceneId: " + sceneId + " with type " + scene.Type + " does not have an inputPin?");
            return null;
        }

        for (const connection in pinElement.Connections) {
            var targetPin = pinElement.Connections[connection].TargetPin;
            if (checkPinCondition(targetPin) !== false) {
                // condition is true or undefined, 
                // determine suitable starting point from this child node
                var childNodeResultArray = determineStartingPoint(pinElement.Connections[connection].Target);
                // if this child result is not null, add this array to the result
                if (childNodeResultArray !== null) {
                    result = result.concat(childNodeResultArray);
                }
            }
        }
    }
    else if (scene.Type === "DialogueFragment") {
        // A DialogueFragment is a good starting point
        myConsoleLog("STARTING POINT SUCCESS: DialogueFragment with Id: " + scene.Properties.Id);
        result = [scene.Properties.Id];
    }
    else if (scene.Type === "Hub") {
        // A Hub is a good starting point
        myConsoleLog("STARTING POINT SUCCESS: Hub with Id: " + scene.Properties.Id);
        result = [scene.Properties.Id];
    }
    else if (scene.Type === "Jump") {
        // A Jump is a good starting point if the condition on the inputPin is satisfied
        var targetPin2 = scene.Properties.TargetPin;
        if (checkPinCondition(targetPin2) !== false) {
            result = [scene.Properties.Id];
        }
    }
    else if (scene.Type === "Condition") {
        myConsoleLog("Examining type Condition ....");
        // First determine if the input condition is true or undefined
        var pinElement2 = findInputPin(scene.Properties.Id);
        if (pinElement2 === null) {
            myConsoleLog("ERROR STARTING POINT : SceneId: " + scene.Properties.Id + " with type " + scene.Type + " does not have an inputPin?");
            return null;
        }
        // 
        if (checkPinCondition(pinElement2.Id) !== false) {
            // condition is true or undefined, 
            // Now check the condition inside:
            let conditionString = scene.Properties.Expression;

            if ((conditionString !== null) && (conditionString !== "")) {
                conditionString = conditionString.replace(/^if/, ""); // strip the "if" from the start
                var outArray = scene.Properties.OutputPins;
                var connArray = [];

                if (checkCondition(conditionString)) {
                    // follow the one output pin
                    connArray = ((outArray !== null) && (outArray !== undefined)) ? outArray[0].Connections : [];
                }
                else {
                    // follow the other pin
                    connArray = ((outArray !== null) && (outArray !== undefined)) ? outArray[1].Connections : [];
                }

                for (const connection in connArray) {
                    var targetPin3 = connArray[connection].TargetPin;
                    if (checkPinCondition(targetPin3) !== false) {
                        // condition is true or undefined, 
                        // determine suitable starting point from this child node
                        var childNodeResultArray2 = determineStartingPoint(connArray[connection].Target);
                        // if this child result is not null, add this array to the result
                        if (childNodeResultArray2 !== null) {
                            result = result.concat(childNodeResultArray2);
                        }
                    }
                }
            }
            else {
                myConsoleLog("WARNING: Condition in Condition node is empty?");
            }
        }
    }

    return result;
}

// --------------------------------------------------------------------------
export function loadDataFromJson(newJsonData = null, callback = null) {

    // supplied data always overrides!
    if (newJsonData !== null) {
        console.log('Loading supplied data...');
        jsonData = newJsonData;
    }
    // if none supplied, check localstorage first. If that doesn't exist, we load from original file
    else {
        // if env file denies use of local storage
        if (process.env.REACT_APP_USE_LOCALSTORAGE === 'false') {
            console.log('Loading original data...');
            jsonData = require('../jsonData/CleanedJsonData/' + baseName);
        }
        // otherwise try and fetch stored data
        else if (localStorage.getItem('data') !== null && localStorage.getItem('data') !== 'null' && localStorage.getItem('data') !== 'undefined') {
            console.log('Loading locally saved data...');
            try {
                jsonData = JSON.parse(localStorage.getItem('data'));
            } catch (err) {
                console.log("error while loading local data. Defaulting to file");
                jsonData = require('../jsonData/CleanedJsonData/' + baseName);
            }
        }
        // if unavailable or otherwise incorrect, default to original data
        else {
            console.log('Loading original data...');
            jsonData = require('../jsonData/CleanedJsonData/' + baseName);

            localStorage.setItem('data', JSON.stringify(jsonData));
        }
    }
    
    models = jsonData.Packages[0].Models

    allTypes = [];

    flowFragments = getArrayByType('FlowFragment');
    dialogues = getArrayByType('Dialogue');
    dialogueFragments = getArrayByType('DialogueFragment');
    hubs = getArrayByType('Hub');
    jumps = getArrayByType('Jump');
    locations = getArrayByType('Location');
    conditions = getArrayByType('Condition');
    entities = getArrayByType('Entity');

    // These are not part of the "flow" so to speak, but should still be searchable
    // (But need not be part of allTypes - in fact, globalVariables cannot be part, since it has no Properties)

    assets = getArrayByType('Asset');
    globalVariables = getJsonData().GlobalVariables;

    if (flowFragments.length !== 0)
        allTypes.push(flowFragments);
    if (dialogues.length !== 0)
        allTypes.push(dialogues);
    if (dialogueFragments.length !== 0)
        allTypes.push(dialogueFragments);
    if (hubs.length !== 0)
        allTypes.push(hubs);
    if (jumps.length !== 0)
        allTypes.push(jumps);
    if (locations.length !== 0)
        allTypes.push(locations);
    if (conditions.length !== 0)
        allTypes.push(conditions);
    if (entities.length !== 0)
        allTypes.push(entities);

    if (callback)
        callback(jsonData);

    return jsonData;
}

// console log only if debug mode true, or always (serious errors)
export const myConsoleLog = (aString, always = false) => {
    if (process.env.REACT_APP_LOG_TO_CONSOLE === 'false')
        return;
    
    if (always)
        console.log(aString);
}