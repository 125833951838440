import React, { useEffect, useState, useRef } from 'react';
import { findAttachments, getVariableWithName, getVariableWithNamespace, findIDGivenName, setVariable } from '../jsonData/DataWrangler';
import { AiOutlineCheckCircle } from 'react-icons/ai'

const LocateCollectableModal = ({ selectedId }) => {

    const [validCollectables, setValidCollectables] = useState([]);
    const [allCollectables, setAllCollectables] = useState([]);
    const [clickResult, setClickResult] = useState('');

    const [collectablesName, setCollectablesName] = useState(getVariableWithName("GameSettings", "collectablesName").Value);
    const [foundCollectables, setFoundCollectables] = useState(getVariableWithName("Playerproperties", "FoundCollectables"));
    
    const correctFirst = `Goedzo, deze collectable was hier correct!`;
    const correctSecond = `Deze collectable heb je al eens gevonden`;
    const incorrect = `Helaas, deze collectable is hier niet aanwezig`;

    var clickResultTimeout = null;
    const mounted = useRef(false);

    // makes sure there is no memory leak when the timeout passes when the modal has been closed already
    useEffect(() => {
        mounted.current = true;
        //setAllCollectables(getVariableWithNamespace('misstandenScores').Variables);

        setAllCollectables(getVariableWithNamespace(collectablesName).Variables);

        return () => { mounted.current = false; }
    }, [])

    // gets valid misstanden when modal is opened from a scene
    useEffect(() => {
        setValidCollectables(findAttachments(selectedId));
    }, [selectedId])

    // whenever clickresult changes and component is mounted will remove result after X seconds
    useEffect(() => {
        if (clickResult !== '' && mounted.current === true) {
            removeClickResult();
        }
    }, [clickResult])

    const isCollectableValid = (collectable) => {

        try {
            const foundId = findIDGivenName(collectable.Variable, true, true);

            if (validCollectables.some(e => e === foundId)) {

                const result = allCollectables.filter(e => e.Variable === collectable.Variable)[0];

                // correct and not previously found
                if (result.Value.toLowerCase() === 'false') {
                    setClickResult(correctFirst);
                    result.Value = 'true';

                    setVariable("Playerproperties.FoundCollectables+=1");
                    setVariable("Playerproperties.Sentiment+=1");
                }
                // correct but has been found before
                else {
                    setClickResult(correctSecond);
                }
                return;
            }

            // incorrect
            setClickResult(incorrect);
            setVariable("Playerproperties.Sentiment-=1");
        } catch (error) {
            console.log(error);
        }
    }

    // sets the clickresult to '' after 3 seconds
    const removeClickResult = () => {
        clearTimeout(clickResultTimeout);

        clickResultTimeout = setTimeout(() => {
            setClickResult('');
        }, 3000);
    }

    return (
        <div className="absolute bg-persona h-full flex flex-col w-full bg-opacity-70 z-10">
            <div className="absolute mx-5 bg-persona-greenLight flex flex-col top-16 bottom-64 rounded-lg shadow-lg">

                <p className="text-lg font-header py-2 text-center text-persona-white bg-persona-greenLight rounded-t-lg shadow-lg px-2">{`Klik de ${collectablesName} aan die je hier vond`}</p>

                <div className="overflow-y-auto flex-grow space-y-2 mx-2 flex flex-col py-2">
                    {
                        allCollectables.map((collectable, key) => {
                            return (
                                <CollectableButton key={key} collectable={collectable} clickAction={isCollectableValid} />
                            )
                        })
                    }
                </div>
                {
                    clickResult === '' ? null : <div className="p-2 rounded-b-lg bg-persona-greenLight text-persona-white font-header text-center text-lg">{clickResult}</div>
                }
            </div>
        </div>
    )
}

export default LocateCollectableModal



export const CollectableButton = ({ collectable, clickAction }) => {

    const collectableFound = collectable.Value.toLowerCase() === 'true';

    return (

        <div className={`shadow-lg cursor-pointer flex py-2 px-3 text-persona-white text-opacity-70 text-left rounded-lg ${collectableFound ? "bg-persona-greenMiddle" : "bg-persona"}`} onClick={() => clickAction(collectable)}>

            <button className="text-left flex-1 mr-12">
                {collectable.Description}
            </button>
            {collectableFound === true &&
                <AiOutlineCheckCircle className="w-10 h-full ml-2 text-persona-greenLight" />
            }
        </div>
    )
}

